<!--
 * @Description: 动态
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-30 10:08:26
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-11 09:18:02
-->
<template>
  <div class="dynamic">
    <div ref="contentTop">
      <div style="width: 94%; margin: 18px auto 0">
        <Search
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          :searchParamList="searchParamList"
          code="dynamic"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进人"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商机状态"
            prop="bizStatusName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进时间"
            prop="createTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="跟进详情"
            prop="memo"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="沟通方式"
            prop="contactTypeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="下次联系时间"
            prop="nextTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="意向状态"
            prop="followStatusName"
            min-width="120"
            
          ></el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getFollowList" />
  </div>
</template>
<script>
import Search from "components/search";
import Detail from "./components/detail";
import { getFollowList } from "api/customer";
import { getDeptList, getUserList } from "api/public";
import { mapGetters } from "vuex";
import { formatTimesHour } from "@/utils/timeDate";

export default {
  name: "dynamic",
  components: {
    Search,
    Detail,
  },
  data() {
    return {
      activeName: "all",
      tabList: [
        { name: "所有", path: "all" },
        { name: "未质检", path: "noinspect" },
        { name: "已质检", path: "inspect" },
        { name: "无需质检", path: "none" },
      ],
      searchShow: false,
      searchAllParam: [
        {
          name: "allotTime",
          label: "跟进日期",
          type: "date",
          ref: "follow",
          show: false,
          value: [formatTimesHour(new Date()), formatTimesHour(new Date())],
        },
        {
          name: "deptId",
          label: "部门",
          type: "tree",
          option: [],
          value: "",
        },
        {
          name: "followStatusList",
          label: "意向状态",
          type: "dictMult",
          code: "",
          value: "",
        },
        {
          name: "userId",
          label: "归属销售",
          type: "select",
          option: [],
          text: "name",
          id: "id",
          value: "",
        },
      ],
      searchParamList: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0
    };
  },
  computed: {
    ...mapGetters(["loginUser"]),
    getTrackType() {
      return this.loginUser.trackType === 1 || this.loginUser.trackType === 3
        ? "FOLLOW_STATUS_ACCOUNT"
        : "FOLLOW_STATUS_SELF_EXAM";
    },
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.searchParamList = this.searchAllParam;
    this.search()
    this.searchAllParam.map((item) => {
      if (item.name === "deptId") {
        getDeptList().then((data) => item.option = data.result);
      } else if (item.name === "userId") {
        getUserList().then((data) => (item.option = data.result));
      } else if (item.name === "followStatusList") {
        item.code = this.getTrackType
      }
    });
    this.$nextTick(() => {
      this.topHeight = this.$refs.contentTop.offsetHeight + 30
    })
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getFollowList() {
      this.listLoading = true;
      const { code, result, msg } = await getFollowList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getFollowList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getFollowList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].column = val.prop;
      this.getFollowList();
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchAllParam.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "allotTime") {
              this.searchParam.beginFollowTime = item.value[0];
              this.searchParam.endFollowTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getFollowList();
    },
    // 重置
    reset() {
      this.searchAllParam.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "allotTime") {
            item.value = [formatTimesHour(new Date()), formatTimesHour(new Date())]
          } else {
            item.value = "";
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
.dynamic {
  // padding-bottom: 40px;
  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}
</style>
